<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.designCode" placeholder="项目编号" />
            </a-form-model-item>
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="工程名称" />
            </a-form-model-item>
            <a-form-model-item>
              <a-select placeholder="管理类别" v-model="form.type" show-search style="width: 160px">
                <a-select-option
                  v-for="item in epcTypeList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <input
                ref="upload"
                style="display: none"
                type="file"
                @change="onFileChange"
                :multiple="false"
              />
              <a-button v-if="isEpcManager" :loading="uploading" @click="importHistoryData">历史数据导入</a-button>

              <a-button @click="$router.push($route.path + '/add')" type="primary">项目启动</a-button>
              <a-button v-if="isEpcManager" type="danger" @click="removeBatch">批量删除</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <Padding />
      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
        :row-selection="{
          selectedRowKeys,
          onChange: onSelectChange,
        }"
      >
        <a-table-column title="项目编号" align="center" width="160px">
          <template slot-scope="text">
            <a class="highlight">{{ text.designCode }}</a>
          </template>
        </a-table-column>
        <a-table-column title="工程名称" align="center" width="400px">
          <template slot-scope="text">
            <a class="highlight">{{ text.name }}</a>
          </template>
        </a-table-column>
        <a-table-column title="管理类别" align="center" data-index="type">
          <template slot-scope="type">
            <DataDictFinder dictType="epc_prj_type" :dictValue="type" />
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center" data-index="status">
          <template v-slot="status">
            <DataDictFinder dictType="epc_review_status" :dictValue="status" iconType="badge" />
          </template>
        </a-table-column>
        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                @click="$router.push($route.path + '/detail?pid=' + text.id)"
                v-if="text.approved"
              >项目阶段</a>
              <a v-else :class="{ disabled: text.status === 'reviewing' }">项目阶段</a>

              <span>
                <a
                  @click="$router.push($route.path + '/update?id=' + text.id)"
                  v-if="text.status !== 'reviewing'"
                >编辑</a>
                <a v-else :class="{ disabled: text.status === 'reviewing' }">编辑</a>
              </span>

              <a @click="$router.push($route.path + '/view?id=' + text.id)">查看</a>
              <a class="danger" @click.prevent="del(text.id)" v-if="isEpcManager">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { fetchList, remove, removeBatch, importHistory } from "@/api/epc";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10,
      selectedRowKeys: [],
      selectRowsId: [],

      uploading: false
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    ...mapGetters("auth", ["isEpcManager"]),

    epcTypeList() {
      return this.findDataDict("epc_prj_type");
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;
      let { designCode, name, status, type } = form;

      fetchList({
        pageNum: current,
        pageSize,
        designCode,
        name,
        status,
        type
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    del(id) {
      const that = this;

      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id
          }).then(() => {
            that.getList();
          });
        }
      });
    },
    removeBatch() {
      var that = this;
      let selectRowsId = this.selectRowsId;
      this.$confirm({
        title: "确认要删除选中项吗？",
        onOk() {
          removeBatch(selectRowsId).then(() => {
            that.getList();
          });
        }
      });
    },

    onSelectChange(keys, text) {
      this.selectRowsId = [];
      this.selectedRowKeys = keys;
      let id;
      for (let item of text) {
        id = item.id;
        this.selectRowsId.push({ id });
      }
    },

    onFileChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.uploading = true;

      importHistory(data)
        .then(() => {
          this.getList();
        })
        .finally(() => {
          this.uploading = false;
          e.target.value = null;
        });
    },
    importHistoryData() {
      this.$refs.upload.click();
    }
  }
};
</script>
<style lang="less" scoped>
.highlight {
  color: rgba(0, 0, 0, 0.65);
  &:hover {
    color: #1890ff;
  }
}

.disabled {
  color: #ccc;
}
</style>
